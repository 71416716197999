import { AppType } from '@aurora/shared-types/app';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import React, { useCallback, useContext, useEffect } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import clientAuthenticationHelper from '../../../helpers/authentication/ClientAuthenticationHelper';
import usePageLoadingGlobalState from '../../../helpers/ui/PageLoadingGlobalState';
import useEndUserRoutes from '../../../routes/useEndUserRoutes';
import AppTypeContext from '../../context/AppTypeContext';
import SwitchUserContext from '../../context/SwitchUserContext/SwitchUserContext';
import useRegistrationStatus from '../../users/useRegistrationStatus';
import useTranslation from '../../useTranslation';
import useAuthTransition from '../useAuthTransition';

interface Props {
  /**
   * Class name(s) to apply to the link element.
   */
  className?: string;
}

/**
 * A logout link that displays when the user is logged.
 *
 * @constructor
 *
 * @author Adam Ayres, Dolan Halbrook
 */
const LogoutLink: React.FC<React.PropsWithChildren<Props>> = ({ className = [] }) => {
  const cx = useClassNameMapper();
  const currentApp = useContext(AppTypeContext);
  const { clearSwitchUserId } = useContext(SwitchUserContext);
  const [, setPageLoading] = usePageLoadingGlobalState('pageLoading');
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.LOGOUT_LINK);
  const { isRegistered } = useRegistrationStatus();
  const { router } = useEndUserRoutes();
  const pageId = router.getCurrentPageName();

  // logging out from the admin application, blog message page, tkb message page,  page editor and theme editor
  // redirects to the end user app community page
  const handleAuthTransition = useAuthTransition(
    currentApp === AppType.ADMIN ||
      (currentApp === AppType.END_USER &&
        (pageId === EndUserPages.PageEditorPage ||
          pageId === EndUserPages.ThemeEditorPage ||
          pageId === EndUserPages.BlogMessagePage ||
          pageId === EndUserPages.TkbMessagePage))
      ? {
          route: EndUserPages.CommunityPage,
          params: null
        }
      : undefined
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleLogout = useCallback(
    async (event: { preventDefault: () => void }): Promise<void> => {
      event.preventDefault();
      setPageLoading(true);
      clearSwitchUserId();
      const loggedOut = await clientAuthenticationHelper.logout();
      if (loggedOut) {
        if (loggedOut.redirectUrl) {
          window.location.href = loggedOut.redirectUrl;
        } else {
          await handleAuthTransition();
        }
      }
    },
    [setPageLoading, clearSwitchUserId, handleAuthTransition]
  );

  // listen for logout events
  useEffect(() => {
    window.addEventListener('kh:logout', handleLogout);
    return () => {
      window.removeEventListener('kh:logout', handleLogout);
    };
  }, [handleLogout]);

  if (textLoading) {
    return null;
  }

  return (
    isRegistered && (
      <button
        type="button"
        onClick={handleLogout}
        className={cx(className)}
        data-testid="LogoutLink"
      >
        {formatMessage('logout')}
      </button>
    )
  );
};

export default LogoutLink;
