import type { CustomRouter, RouteWithOptions } from './useCustomRouter';
import useEndUserRoutes from './useEndUserRoutes';

import { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { BaseRouteAndParams } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';

export type EndUserRouter = CustomRouter<EndUserPages, EndUserQueryParams>;

/**
 * Url path and route of the next page to redirect to.
 */
export interface NextPageData {
  /**
   * The next page URL.
   */
  nextUrl: string | null;
  /**
   * The next page route.
   */
  nextRoute: RouteWithOptions<EndUserPages, EndUserQueryParams>;
}

/**
 * Gets info about the next page; the next page is specified in the "next"
 * query param. If there is no "next" query param, or the specified value
 * does not resolve to a page, then the community page URL is returned.
 *
 * @param fallback the fallback route to use if there is no next query param specified.
 * By default, this will use the community page, however a null value can be passed to
 * have no fallback.
 *
 * @author Adam Ayres
 */
export default function useEndUserNextPage<
  RouteType extends EndUserPages,
  QueryType extends EndUserQueryParams
>(
  fallback: RouteWithOptions<RouteType, QueryType> | null = {
    route: EndUserPages.CommunityPage,
    params: {}
  } as RouteWithOptions<RouteType, QueryType>
): NextPageData {
  const { router } = useEndUserRoutes();
  const nextUrlQueryParam = router.getUnwrappedQueryParam(EndUserQueryParams.NEXT_PAGE, null);

  if (nextUrlQueryParam) {
    const route = router.getRouteAndParamsByPath(nextUrlQueryParam);

    if (route?.route) {
      return {
        nextUrl: nextUrlQueryParam,
        nextRoute: route
      };
    }
  }

  if (fallback) {
    const nextUrl = router.getRelativeUrlForRoute<BaseRouteAndParams<RouteType>>(
      fallback.route,
      fallback.params,
      fallback.query
    );

    return {
      nextUrl,
      nextRoute: fallback
    };
  }

  return {
    nextUrl: null,
    nextRoute: null
  };
}
